var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{},[_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.titlePageHeader))])])],1),_c('v-row',{staticClass:"justify-space-between align-center"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersTable,"items":_vm.payments,"search":_vm.search,"loading":_vm.loadTable,"loading-text":"Por favor espere...","sort-by":"full_name","no-data-text":"No se hay comentarios registrados"},scopedSlots:_vm._u([{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(item.start_date)))])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(item.end_date)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.total)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('v-chip',{attrs:{"dark":"","color":"success","small":"","label":""}},[_c('span',[_vm._v("Activo")])]):_c('v-chip',{attrs:{"color":"red","small":"","label":"","dark":""}},[_c('span',[_vm._v("Inactivo")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Refrescar ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }