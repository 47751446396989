<template>
  <div>
    <v-row class="">
      <v-col
        ><h2>{{ titlePageHeader }}</h2>
      </v-col>
    </v-row>
    <v-row class="justify-space-between align-center">
      <v-col cols="12" md="4">
        <v-text-field v-model="search" label="Buscar"></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headersTable"
      :items="payments"
      :search="search"
      :loading="loadTable"
      loading-text="Por favor espere..."
      sort-by="full_name"
      no-data-text="No se hay comentarios registrados"
      class="elevation-1"
    >
      <template v-slot:item.start_date="{ item }">
        <p>{{ item.start_date | formatDate }}</p>
      </template>
      <template v-slot:item.end_date="{ item }">
        <p>{{ item.end_date | formatDate }}</p>
      </template>
      <template v-slot:item.total="{ item }">
        <p>{{ item.total | formatCurrency }}</p>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <pre>{{ item.is_active }}</pre> -->
        <v-chip dark v-if="item.is_active" color="success" small label>
          <span>Activo</span>
        </v-chip>
        <v-chip v-else color="red" small label dark>
          <span>Inactivo</span>
        </v-chip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Refrescar </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "../../filters/moment";
import { formatCurrency } from "../../filters/currency";

export default {
  data() {
    return {
      search: "",
      headersTable: [
        { text: "Orden", value: "order.id" },
        { text: "Total", value: "order.total" },
        { text: "Servicio", value: "order.details.product.name" },
        { text: "Fecha inicio", value: "start_date" },
        { text: "Fecha culminación", value: "end_date" },

        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      titlePageHeader: "Lista de pagos",
      payments: [],
      loadTable: false,
    };
  },
  filters: {
    formatDate,
    formatCurrency,
  },
  computed: {
    // ...mapGetters("comment", ["getErrors", "getAllComments"]),
    // formTitle() {
    //   return this.editedIndex === -1
    //     ? "Crear comentario"
    //     : "Información del comentario";
    // },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    this.loadTable = true;
    await this.initialize();
    this.loadTable = false;
  },
  methods: {
    ...mapActions("payment", ["getAllMypayments", "setErrors"]),

    async initialize() {
      this.payments = await this.getAllMypayments();
      // console.log(this.payments);
      this.setErrors("clear");
    },
    async showItem(item) {
      // this.editedItem = Object.assign({}, { ...item });
      this.$router.push({ name: "OrderDetail", params: { id: item.id } });
      // this.editedIndex = 1;
      // this.dialog = true;
    },
  },
};
</script>

<style>
</style>
