<template>
  <PaymentList />
</template>

<script>
import PaymentList from "../../components/dashboard/PaymentList.vue";
export default {
  name: "MyPayments",
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Mis Pagos",
      };
    },
  },
  components: {
    PaymentList,
  },
};
</script>

<style scoped>
</style>
